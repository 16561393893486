var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-column align-center justify-space-between receipt-lottery-page"
      },
      [
        _c("div", [
          _c("h4", { staticClass: "text-uppercase px-2" }, [
            _vm._v(_vm._s(_vm.$t("receiptLottery.title")))
          ]),
          _vm.code
            ? _c(
                "div",
                {
                  key: _vm.key,
                  staticClass: "pa-2 d-flex flex-column justify-space-between"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-center align-center flex-column"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "barcode-wraper" },
                        [
                          _c("barcode", {
                            staticClass: "align-self-center",
                            attrs: {
                              value: _vm.code,
                              format: "CODE128",
                              displayValue: false,
                              flat: ""
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-n2 text-body-0 text-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("receiptLottery.number")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-h3 font-weight-bold mb-2 text-center"
                            },
                            [_vm._v(" " + _vm._s(_vm.code) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "my-3 mx-8 text-center pre-wrap" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("receiptLottery.info")) + " "
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            : _c(
                "div",
                { staticClass: "px-2" },
                [
                  _c("div", { staticClass: "text-body-0" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("receiptLottery.description")) + " "
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-6",
                    attrs: {
                      label: `${_vm.$t("receiptLottery.code")}`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      height: "50",
                      "append-icon": "$barcodeScan"
                    },
                    on: { "click:append": _vm.scanCard },
                    model: {
                      value: _vm.insertedCode,
                      callback: function($$v) {
                        _vm.insertedCode = $$v
                      },
                      expression: "insertedCode"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-6",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        rounded: "",
                        "x-large": "",
                        block: "",
                        loading: _vm.loading,
                        disabled: !_vm.insertedCode
                      },
                      on: { click: _vm.addCard }
                    },
                    [_vm._v(_vm._s(_vm.$t("receiptLottery.addCard")))]
                  )
                ],
                1
              )
        ]),
        _vm.code
          ? _c(
              "div",
              { staticClass: "bottom-part" },
              [
                _c(
                  "div",
                  { staticClass: "lottery-card" },
                  [
                    _c("v-img", {
                      attrs: {
                        src: "/img_layout/lottery/logo.png",
                        contain: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "my-2",
                    attrs: { text: "", depressed: "", rounded: "", small: "" },
                    on: { click: _vm.removeCard }
                  },
                  [_vm._v(_vm._s(_vm.$t("receiptLottery.removeCard")))]
                )
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }